import React from "react";
import { Route, Switch } from "react-router-dom";

import { SendOrderForm } from "./send-order";
import { GetPricesForm } from "./get-prices";

export default () => {
    return {
        routes: [
            {
                path: "/test-harnesses",
                render: ({ match }) => (
                    <Switch>
                        <Route
                            path={`${match.url}/get-prices`}
                            exact
                            component={GetPricesForm}
                        />
                        <Route
                            path={`${match.url}/send-order`}
                            exact
                            component={SendOrderForm}
                        />
                    </Switch>
                ),
            },
        ],
    };
};
