import { menuItems } from "@peracto/peracto";
import { faBoxFull } from '@fortawesome/pro-regular-svg-icons/faBoxFull'
import { faTools } from '@fortawesome/pro-regular-svg-icons/faTools'

const ROLE_ADMIN = 'ROLE_ADMIN'

export const menu = {
    ...menuItems,
    products: {
        label: 'Products',
        icon: faBoxFull,
        sortOrder: 30,
        roles: [ROLE_ADMIN],
        children: {
            allProducts: {
                label: 'All Products',
                path: '/products',
                sortOrder: 10,
                roles: [ROLE_ADMIN],
            },
            productVariants: {
                label: 'Product Variants',
                path: '/variants',
                sortOrder: 20,
                roles: [ROLE_ADMIN],
                feature: 'products.productVariants',
            },
            productRestrictions: {
                label: 'Product Restrictions',
                path: '/product-restrictions',
                sortOrder: 30,
                roles: [ROLE_ADMIN],
            },
            categories: {
                label: 'Categories',
                path: '/categories',
                sortOrder: 40,
                roles: [ROLE_ADMIN],
            },
            attributes: {
                label: 'Attributes',
                sortOrder: 50,
                roles: [ROLE_ADMIN],
                children: {
                    allAttributes: {
                        label: 'All Attributes',
                        path: '/attributes',
                        sortOrder: 10,
                        roles: [ROLE_ADMIN],
                    },
                    attributeGroups: {
                        label: 'Attribute Groups',
                        path: '/attribute-groups',
                        sortOrder: 20,
                        roles: [ROLE_ADMIN],
                    },
                    attributeSets: {
                        label: 'Attribute Sets',
                        path: '/attribute-sets',
                        sortOrder: 30,
                        roles: [ROLE_ADMIN],
                    },
                },
            },
        },
    },
    testHarnesses: {
        label: "Test Harnesses",
        icon: faTools,
        sortOrder: 72,
        roles: [ROLE_ADMIN],
        children: {
            getPrices: {
                label: "Get Prices",
                sortOrder: 10,
                roles: [ROLE_ADMIN],
                path: "/test-harnesses/get-prices",
            },
            sendOrder: {
                label: "Send Order",
                sortOrder: 20,
                roles: [ROLE_ADMIN],
                path: "/test-harnesses/send-order",
            },
        },
    },
};
