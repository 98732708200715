/* eslint-disable */

import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { Helmet } from 'react-helmet'

import {
    HydraTable,
    Heading,
    TextFilter,
    StyledCheckbox,
} from '@peracto/peracto-ui'

import { GET_LIST, useClient } from '@peracto/client'

const ProductRestrictionsList = () => {
    // Default visible columns - key = column accessor
    const [selectedColumns, onSelectColumn] = useState()

    const { client } = useClient()

    // The 'selectable' property is used to define if the
    // column is available in the 'manage columns' dropdown
    const columns = useMemo(() => [
        {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
                <StyledCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => 
                <StyledCheckbox
                    {...row.getToggleRowSelectedProps()}
                    data-testid={`row-${row.index}-checkbox`}
                />,
            selectable: false,
        },
        {
            Header: 'SKU',
            accessor: 'sku',
            sortType: 'basic',
            selectable: false,
        },
        {
            Header: 'SKU',
            accessor: 'product.sku',
            sortType: 'basic',
            Filter: props => <TextFilter label="SKU" {...props} />,
            selectable: false,
            show: false,
        },
        {
            Header: 'Account Number',
            accessor: 'accountNumber',
            sortType: 'basic',
            Filter: props => <TextFilter label="Account Number" {...props} />,
            selectable: false,
        },
    ])

    const onFetchData = options => client(GET_LIST, 'product-restrictions', options)

    return (
        <>
            <Helmet>
                <title>Product Restrictions | Peracto</title>
            </Helmet>
            <Heading name="Product Restrictions">
                <div className="ml-2 d-flex align-items-center">
                    <Link
                        className="mr-2 btn btn-outline-primary"
                        data-testid="import-product-restrictions"
                        to="/product-restrictions/import"
                    >
                        <FontAwesomeIcon icon={faUpload} className="mr-2" />
                        Import Product Restrictions Data
                    </Link>
                </div>
            </Heading>

            <div className="row">
                <div className="col">
                    <div className="card card--transparent">
                        <div className="card-body">
                            <HydraTable
                                columns={columns}
                                selectedColumns={selectedColumns}
                                onFetchData={onFetchData}
                                onSelectColumn={onSelectColumn}
                                defaultFilters={{
                                    fromUrl: '',
                                    toUrl: '',
                                    active: 'true',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductRestrictionsList
