import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProductRestrictionsList from './ProductRestrictionsList'
import ProductRestrictionsImport from './ProductRestrictionsImport'
import ProductRestrictionsImportStatus from './ProductRestrictionsImportStatus'

export default () => {
    return {
        routes: [
            {
                path: '/product-restrictions',
                render: ({ match }) => (
                    <Switch>
                        <Route path={`${match.url}`} exact component={ProductRestrictionsList} />
                        <Route path={`${match.url}/import`} exact component={ProductRestrictionsImport} />
                        <Route
                            path={`${match.url}/import/:id`}
                            exact
                            component={ProductRestrictionsImportStatus}
                        />
                    </Switch>
                ),
            }
        ],
    }
}

